<template>
  <b-card-code>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      v-b-modal.modal-login
    >
      创建任务
    </b-button>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="添加任务"
     
    >
    <b-form-group>
      <label for="module"  >用户池标签:&nbsp;</label>
      <b-button variant="relief-info" v-b-modal.modal-userpool> 选择标签 </b-button>&nbsp;
      <b-button variant="relief-info" v-b-modal.modal-login>
        上传Excel
      </b-button>
      </b-form-group>
      <b-form-group>
      <label for="module"  >选择的标签:&nbsp;</label>
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-1"
          >
            <li
              v-for="tag in tags"
              :key="tag"
              class="list-inline-item"
            >
              <b-form-tag
              remove-on-delete="true"
                :title="tag"
                variant="primary"
                @remove="removeTag(tag)"
              >
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>
      </b-form-group>
        <b-modal
      id="modal-userpool"
      cancel-variant="outline-secondary"
      centered
      title="选择用户标签"
           ok-title="添加"
      cancel-title="关闭"
    >   
     <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
     <vue-good-table
      mode="remote"
      :columns="columns1"
      :rows="rows1"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
      @on-selected-rows-change="selectionChanged"
    >
      <template slot="table-row" slot-scope="props">
        
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="aa(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deletebyid(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total1 }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total1"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="导入用户资料"
    >
      <b-form>
        <b-form-group>
          <label for="module">上传Excel:</label>
          <b-form-file
            accept=".xls, .xlsx"
            @input="uploadChange"
            v-model="file"
          >
            <template slot="file-name" slot-scope="{ names }">
              <b-badge variant="primary">
                {{ names[0] }}
              </b-badge>
            </template>
          </b-form-file>
          <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click=""
          >
            下载模版
          </b-button>
        </b-form-group>
        <b-progress v-model="pvalue" max="100" />
        </br>
        <div align="right">
          <b-button variant="relief-success" @click="submit">
            上传数据
          </b-button>
        </div>
      </b-form>
    </b-modal>
    
    <b-form-group label="执行部门" label-for="dname">
      <b-form-select
       size="sm"
        v-model="roleSelect"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="roleOptions"
        :reduce="(val) => val.value"
        :clearable="false"
        input-id="user-dname"
      />
    </b-form-group>
   
    <b-form-group>
      <label for="deviceid">单帐号加粉数量:</label>

      <cleave
       size="sm"
        id="number"
        v-model="number"
        class="form-control"
        :raw="false"
        :options="numbers"
        placeholder="10,000"
      />
    </b-form-group>
    <b-form-group>
      <label for="deviceid">每个帐号加粉间隔时间:</label>

      <cleave
       size="sm"
        id="number"
        v-model="time"
        class="form-control"
        :raw="false"
        :options="numbers"
        placeholder="10,000"
      />
    </b-form-group>
     <b-form-group>
      <label for="deviceid">打招呼:</label>
  <b-form-input
          id="basicInput"
          placeholder="您好，我是"
          v-model="messages"
        />
    </b-form-group>
   
    <b-form-group label="加粉时间" label-for="nickname">
     <flat-pickr
          v-model="dateNtim"
          class="form-control"
         :config="config"
        />
    </b-form-group>
      <div align="right">
       <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="addtask"
          >
            提交
          </b-button>   <b-button
            size="sm"
            variant="outline-primary"
            class="ml-auto"
            @click="restask"
          >
            重置
          </b-button>
      </div>
    </b-modal>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'create_time'"
        >
          {{ timestampToTime(props.row.create_time) }}
        </span>
        <span v-else-if="props.column.field === 'state'" class="text-nowrap">
            <b-badge :variant="statusVariant(props.row.state)">
            {{ props.row.state=="1"?"开启":"关闭"}}
            </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="aa(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>编辑</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deletebyid(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  VBTooltip,
  BAvatar,
  BBadge,
  BButtonClose,
  BFormFile,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BFormTags,
  BFormTag,
  BForm,
  BPopover,
  BProgress,
  VBPopover,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Cleave from "vue-cleave-component";
import "prismjs";
import { Mandarin } from "flatpickr/dist/l10n/zh.js"; //引入普通话语言包
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import * as XLSX from "xlsx"; // 数据导出导入所需要的依赖，
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    flatPickr,
    Cleave,
    VBTooltip,
    BButtonClose,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BFormTags,
    BFormTag,
    BProgress,
    BBadge,
    BFormFile,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BForm,
    BModal,
    VBModal,
    BPopover,
    VBPopover,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      log: [],
      locale: "zh",
      pageLength: 10,
      total: 0,
      cid: 0,
      rs: false,
      roleSelect: "",
      number: 5,
      aid: "",
      tags: [],

      file: null,
      pvalue: 0,
      selectid: [],
      phonedata: [],
      wechatnick: "",
      time: 60,
      messages: "",
      desc: "",
      options: "",
      accountSelect: "",
      total1: 0,
      search: "",
      roleOptions: [],
      dateNtim: null,
      numbers: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      config: {
        wrap: true,
        altInput: true,
        dateFormat: "U", //data变成时间戳
        altFormat: "Y-m-d H:i", //选择时显示的时间
        enableTime: true, //选择小时分种
        time_24hr: true, //时间24小时制
        locale: Mandarin, //中文
        minuteIncrement: 1,
      },

      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "执行部门",
          field: "name",
        },
        {
          label: "任务状态",
          field: "state",
        },
        {
          label: "粉丝标签",
          field: "tag",
        },
        {
          label: "加粉总数",
          field: "totalsize",
        },
        {
          label: "执行数量",
          field: "doingsize",
        },

        {
          label: "通过数量",
          field: "successsize",
        },

        {
          label: "单号主动加粉上限",
          field: "sayhellosize",
        },

        {
          label: "执行时间",
          field: "execute_time",
        },
        {
          label: "创建时间",
          field: "create_time",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      columns1: [
        {
          label: "标签",
          field: "tag",
        },
        {
          label: "用户池数量",
          field: "num",
        },
      ],
      rows: [],
      rows1: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        0: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    this.aid = user.id;
    console.log("user", JSON.stringify(user));
    useJwt
      .GetAutoFriendAddTaskList({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        console.log("任务列表", JSON.stringify(res));
        this.total = res.data.data.total;
        this.rows = res.data.data.list;
      });

    useJwt
      .queryphonetagnum({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        console.log("res", JSON.stringify(res.data.data.rows));
        this.total1 = res.data.data.records;
        this.rows1 = res.data.data.rows;
      });
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;
        console.log("response.data.data", response.data.data);
        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
    const now = new Date();

    this.dateNtim = Date.parse(new Date());
  },
  methods: {
    async uploadChange(file) {
      let dataBinary = await this.readFile(file);
      console.log(XLSX);
      let workBook = XLSX.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(workSheet);
      this.phonedata = data;
    },
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      
      const D = `${date.getDate()>9?date.getDate():"0"+date.getDate()} `;
      const h = `${date.getHours()>9?date.getHours():"0"+date.getHours()}:`;
      const m = `${date.getMinutes()>9?date.getMinutes():"0"+date.getMinutes()}:`;
      const s = date.getSeconds()>9?date.getSeconds():"0"+date.getSeconds()
      return Y + M + D + h + m + s;
    },
    readFile(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        console.log("file", file);
        reader.readAsBinaryString(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
      });
    },
    removeTag(tag) {
      console.log(JSON.stringify(tag));
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i] == tag) {
          this.tags.splice(i, 1);
        }
      }
    },
    selectionChanged(params) {
      this.tags = [];
      this.selectid = params.selectedRows;

      for (let i = 0; i < this.selectid.length; i++) {
        this.tags.push(this.selectid[i].tag);
      }
    },
    addtask() {
      if (this.tags.length == 0) {
        this.$swal({
          icon: "error",
          title: "请选择标签!",
          text: "选择标签",
          customClass: {
            confirmButton: "btn btn-error",
          },
        });
        return;
      }
      var friendnum = 0;
      for (let i = 0; i < this.selectid.length; i++) {
        for (let k = 0; k < this.tags.length; k++) {
          if (this.selectid[i].tag == this.tags[k]) {
            friendnum = friendnum + this.selectid[i].num;
          }
        }
      }
      var taglist = "";
      for (let k = 0; k < this.tags.length; k++) {
        taglist = taglist + this.tags[k] + ",";
      }

      taglist = taglist.substring(0, taglist.length - 1);
      console.log("this.dateNtim", this.dateNtim);
      useJwt
        .addfriendtask({
          cid: this.cid,
          execute_time: this.dateNtim,
          accountid: this.aid,
          did: this.roleSelect,
          tag: taglist,
          between_time: this.time,
          totalsize: friendnum,
          sayhellosize: this.number,
          message: this.messages,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `自动加粉任务添加成功！`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `要加粉${friendnum}人`,
              },
            });
          }
        });
    },
    restask() {
      if (this.tags == null) {
        return;
      }
    },
    submit() {
      var num = 1;
      var successnum = 0;
      var failnum = 0;
      this.phonedata.forEach((item, i) => {
        console.log(item["手机号码（必填）"]);
        useJwt
          .addphonenum({
            cid: this.cid,
            phonenumber: item["手机号码（必填）"],
            nickname: item["昵称"],
            tag: item["标签"],
            did: this.did,
            channel: item["渠道"],
          })
          .then((res) => {
            if (res.data.code == "0") {
              successnum = successnum + 1;
            } else {
              failnum = failnum + 1;
            }
            this.pvalue = (num / this.phonedata.length) * 100;
            console.log("this.pvalue", this.pvalue);
            console.log(this.pvalue);
            if (this.pvalue == 100) {
              var timeout = 8000;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `导入完成`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `总共${this.phonedata.length}条，上传成功${successnum}，重复数据${failnum}，导入完成！`,
                  },
                },
                {
                  position: "top-center",
                  timeout,
                }
              );
            }
            num = num + 1;
          });
      });
    },

    onContext(ctx) {
      this.context = ctx;
    },
    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "error",
            title: "删除失败!",
            text: "请联系管理员处理",
            customClass: {
              confirmButton: "btn btn-error",
            },
          });
        }
      });
    },
    handleSearch(searching) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          wechatnick: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          wechatnick: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .groupconfigAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
